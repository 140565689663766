<template>
  <div class="menu">
    <section class="top">
      <div>
        <div>
          <p>
            메뉴소개
          </p>
        </div>
      </div>
    </section>
    <section class="menus">
      <div>
        <p>좋은 재료와 안전한 먹거리로 </p>
        <h5>옛 맛과 정성을 지켜나가겠습니다.</h5>
        <div class="menus">
          <div class="item" v-for="menu in menus">
            <div :style="{backgroundImage:'url('+menu.img+')'}">

            </div>
            <h5>{{menu.name}}</h5>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data(){
    return {
      menus:[
        {
          name:'(특)설렁탕',
          img:require('../assets/menu/설렁탕.jpeg')
        },
        {
          name:'사골(특)갈비탕',
          img:require('../assets/menu/갈비탕.jpeg')
        },
        {
          name:'도가니탕',
          img:require('../assets/menu/도가니탕.jpeg')
        },
        {
          name:'꼬리곰탕',
          img:require('../assets/menu/꼬리곰탕.jpeg')
        },
        {
          name:'사골삼계탕',
          img:require('../assets/menu/사골삼계탕.jpeg')
        },
        {
          name:'사골만둣국',
          img:require('../assets/menu/사골떡만둣국.jpeg')
        },
        {
          name:'모듬수육',
          img:require('../assets/menu/모듬수육.jpeg')
        },
      ],
    }
  }
}
</script>
