<template>
  <div class="shop">
    <section class="top">
      <div>
        <div>
          <p>
            매장찾기
          </p>
        </div>
      </div>
    </section>
    <section class="shops">
      <div>
        <p>좋은 재료와 안전한 먹거리로 </p>
        <h5>옛 맛과 정성을 지켜나가겠습니다.</h5>
        <div class="filters">
          <div class="filter">
            <select v-model="location" name="" id="">
              <option :value="null">시 선택</option>
              <option v-for="l in locations" :value="l">{{l}}</option>
            </select>
           <button>검색</button>
          </div>
          <div class="filter">
            <input placeholder="매장명 입력" type="text" v-model="name">
            <button>검색</button>
          </div>
        </div>
        <div class="shops">
          <div class="item" v-for="shop in filteredShop" :style="{backgroundImage:'url('+shop.img+')'}">
            <h5><span>{{shop.name}}</span>{{shop.address}}</h5>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import shops from "../shops";
export default {
  name: "Shop",
  computed:{
    filteredShop(){
      var list = shops
      if(this.name){
        list = list.filter(v=>v.name.indexOf(this.name)!==-1)
      }
      if(this.location){
        list = list.filter(v=>v.address.indexOf(this.location)!==-1)
      }
      return list
    },
    locations(){
      var list = shops.map(v=>{
        return v.address.split(' ')[1]
      }).filter((v,i,s)=>{
        return s.indexOf(v)===i
      })
      return list
    }
  },
  data(){
    return {
      shops:shops,
      location:null,
      name:'',
      show:{
        location:false
      }
    }
  }
}
</script>

<style scoped>

</style>
