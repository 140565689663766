<template>
  <div class="info">
    <section class="top">
      <div class="title">
        <div>
          <p>
            창업안내
          </p>
        </div>
      </div>
      <div class="buttons mobile">
        <button class="gold">창업 비용 알아보기</button>
        <button class="red">무료 컨설팅 신청하기</button>
      </div>
      <div class="specifics">
        <div class="specific">
          <h3>sales</h3>
          <h5>정직한 맛, 높은 회전율</h5>
          <p>
            정직한 식재료 <br>
            정직한 맛. <br>
            정직한 매출.
          </p>
        </div>
        <div class="specific">
          <h3>recipe</h3>
          <h5>간편한 조리시스템</h5>
          <p>
            누구나 쉽고 빠르게. <br>
            간편하고 정량화된 <br>
            레시피를 제공합니다.
          </p>
        </div>
        <div class="specific">
          <h3>support</h3>
          <h5>체계적 관리 지원</h5>
          <p>
            처음 시작할 때의 마음가짐으로, <br>
            놓치는 것 없이 든든하게 <br>
            도와드리겠습니다.
          </p>
        </div>
      </div>
      <div class="buttons">
        <button class="gold">창업 비용 알아보기</button>
        <button class="red">무료 컨설팅 신청하기</button>
      </div>
    </section>
    <section class="cost">
      <div>
        <h3>cost</h3>
        <h4>창업 비용</h4>
        <div class="table">
          <div class="row gold">
            <div class="col">
              항목<span> 및 </span>
            </div>
            <div class="col">
              비용 (30평 기준)
            </div>
          </div>
          <div class="row red mo">
            <div class="col">
              항목
            </div>
            <div class="col">
              12,930만
            </div>
          </div>
          <div class="row" v-for="item in list">
            <div class="col">
              {{item.key}}
            </div>
            <div class="col">
              {{item.value}}
            </div>
          </div>
          <div class="row red">
            <div class="col">
              총 비용
            </div>
            <div class="col">
              12,930만
            </div>
          </div>
          <p>
            *철거비, 외관비용, 전기증설, 가스설비, 소방설비, 정화조등 별도 <br>
            *대형매장 별도 협의
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name:'Info',
  data(){
    return {
      list:[
        {
          key:'가맹비',
          value:'550만 (부가세 포함), 대형매장 별도 협의'
        },
        {
          key:'교육비',
          value:'330만 (부가세 포함)'
        },
        {
          key:'물류보증금',
          value:'500만'
        },
        {
          key:'인테리어',
          value:'6,650만'
        },
        {
          key:'간판',
          value:'300만 (파사드비용 별도)'
        },
        {
          key:'가구',
          value:'1,500만 (4인 테이블, 의자 기준)'
        },
        {
          key:'주방기기',
          value:'1,200만'
        },
        {
          key:'기물',
          value:'1,200만'
        },
        {
          key:'냉난방기',
          value:'700만'
        },
      ]
    }
  }
}
</script>
